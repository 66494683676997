import { Component, Ref, Mixins } from "vue-property-decorator";
import debounce from "lodash.debounce";
import API from "@api";
import { formatMoney, isEmpty, isPositiveNumber } from "@helpers";
import { cardGroupModule } from "@store/namespaces";
import { VForm } from "@/types/vuetify";
import {
  MAX_NUM_CARDS_BATCH_ISSUE,
  MIN_AMOUNT_LIMIT,
  MIN_NUM_CARDS_BATCH_ISSUE,
} from "@config/base";
import { CardGroupActions } from "@store/modules/card/modules/group/types";
import { CardGroup, CardType } from "@/types/card";

import IssueCardMixin from "../../mixins/issue-card.mixin";

const defaultDailyLimit = 50;
const defaultTotalLimit = 100;

@Component
export default class IssuePostpaidCardForm extends Mixins(IssueCardMixin) {
  @Ref("formCardLimit") private readonly formCardLimitRef?: VForm;

  @cardGroupModule.Action("createCardGroup")
  private readonly createCardGroupAction!: CardGroupActions["createCardGroup"];

  private form: {
    note: string;
    numCards: number;
    totalLimit: number;
    dailyLimit: number;
    cardGroup: CardGroup | string | null;
    noDailyLimit: boolean;
    noTotalLimit: boolean;
  } = {
    note: "",
    numCards: 1,
    dailyLimit: defaultDailyLimit,
    totalLimit: defaultTotalLimit,
    cardGroup: null,
    noDailyLimit: false,
    noTotalLimit: false,
  };

  private completed = false;
  private loading = false;

  private get minLimitAmount() {
    return MIN_AMOUNT_LIMIT;
  }

  private get title() {
    return this.$vuetify.lang.t("$vuetify.dashboard.issue_limit_card");
  }

  private get totalInfo() {
    const isValidNumCards =
      this.fieldRules.numCards(this.form.numCards.toString()) === true;

    const cardIssueCost =
      isValidNumCards && this.selectedBank
        ? this.form.numCards * this.selectedBank.cardIssueCost
        : 0;

    const fields: {
      text: string;
      value: number;
      bold?: boolean;
    }[] = [
      {
        text: this.$vuetify.lang.t("$vuetify.fields.card_issue"),
        value: cardIssueCost,
      },
    ];

    if (this.numFreeCards) {
      fields.push({
        text: this.$vuetify.lang.t("$vuetify.fields.promocode"),
        value: this.selectedBank
          ? -Math.min(
              this.numFreeCards * this.selectedBank.cardIssueCost,
              cardIssueCost
            )
          : 0,
      });
    }

    if (fields.length > 1) {
      const totalValue = fields.reduce((sum, { value }) => sum + value, 0);

      fields.push({
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.total"),
        value: totalValue,
        bold: true,
      });
    }

    return fields.map((item) => ({
      ...item,
      originalValue: item.value,
      value: formatMoney({
        value: item.value,
        currency: this.selectedBank?.currency,
      }),
    }));
  }

  private get validIssueCardForm() {
    return !!this.selectedBank;
  }

  private get dailyLimit() {
    return this.form.noDailyLimit ? this.totalLimit : +this.form.dailyLimit;
  }

  private get totalLimit() {
    return this.form.noTotalLimit
      ? Math.max(this.calcAvailableBalance, 0)
      : +this.form.totalLimit;
  }

  private get numFreeCards() {
    return Math.max(
      this.profileFreeCardsTotalPromocode.bonus -
        this.profileFreeCardsTotalPromocode.bonusUsed,
      0
    );
  }

  private get cardTotalIssueCost() {
    const numCards = this.form.numCards;

    const cardTotalIssueCost = this.selectedBank
      ? Math.max(numCards - this.numFreeCards, 0) *
        this.selectedBank.cardIssueCost
      : 0;

    return cardTotalIssueCost;
  }

  private get calcAvailableBalance() {
    const { originalValue } = this.totalInfo[this.totalInfo.length - 1];

    return this.currentBalance - originalValue;
  }

  private get isInsufficientBalance() {
    return this.calcAvailableBalance < 0;
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      isSafeInteger: (v: string) =>
        Number.isSafeInteger(+v) ||
        this.$vuetify.lang.t("$vuetify.errors.only_integer_number"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
      minLimitAmount: (v: string) => {
        const currency = this.selectedBank?.currency;

        return (
          +v >= this.minLimitAmount ||
          this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: this.minLimitAmount,
              currency,
            })
          )
        );
      },
      numCards: (v: string) => {
        if (this.fieldRules.required(v) !== true) {
          return this.fieldRules.required(v);
        }

        if (this.fieldRules.positiveNumber(v) !== true) {
          return this.fieldRules.positiveNumber(v);
        }

        if (this.fieldRules.isSafeInteger(v) !== true) {
          return this.fieldRules.isSafeInteger(v);
        }

        if (+v > MAX_NUM_CARDS_BATCH_ISSUE) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_value",
            MAX_NUM_CARDS_BATCH_ISSUE
          );
        }

        if (+v < MIN_NUM_CARDS_BATCH_ISSUE) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_value",
            MIN_NUM_CARDS_BATCH_ISSUE
          );
        }

        return true;
      },
      limit: (val: string | number) => {
        const numCards = this.form.numCards;

        if (!isPositiveNumber(numCards)) {
          return true;
        }

        const limit = +val;
        const currency = this.selectedBank?.currency;
        const balance = this.calcAvailableBalance;

        if (balance < limit) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.card.limit_balance",
            formatMoney({
              value: limit + this.cardTotalIssueCost,
              currency,
            })
          );
        }

        return true;
      },
      dailyLimit: () => {
        return this.fieldRules.limit(this.dailyLimit);
      },
      totalLimit: () => {
        const dailyLimit = this.dailyLimit;
        const totalLimit = this.totalLimit;

        if (totalLimit < dailyLimit) {
          return this.$vuetify.lang.t("$vuetify.errors.card.total_daily_limit");
        }

        return this.fieldRules.limit(totalLimit);
      },
    };
  }

  private async onChangeCardGroup(val: string | CardGroup) {
    this.form.cardGroup = val;
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    this.$emit("update:loading", loading);
  }

  private validateFormCardLimit() {
    return !this.formCardLimitRef || this.formCardLimitRef.validate();
  }

  private async checkBatchIssueCard() {
    if (
      this.loading ||
      !this.validateFormCardLimit() ||
      this.isInsufficientBalance ||
      !this.selectedBank
    ) {
      return false;
    }

    this.setLoading(true);

    await this.fetchAvailablePromocodesAction();
    await this.$nextTick();

    if (!this.validateFormCardLimit()) {
      this.setLoading(false);

      return false;
    }

    this.setLoading(false);

    return true;
  }

  private async batchIssueCard() {
    const checked = await this.checkBatchIssueCard();

    if (!checked || !this.selectedBank) return;

    this.setLoading(true);

    try {
      const {
        noDailyLimit,
        noTotalLimit,
        totalLimit,
        dailyLimit,
        cardGroup,
        ...data
      } = this.form;

      const { id: bankId } = this.selectedBank;

      let cardGroupId: number | undefined;

      if (typeof cardGroup === "string") {
        const { id: newCardGroupId } = await this.createCardGroupAction({
          name: cardGroup,
        });

        cardGroupId = newCardGroupId;
      } else {
        cardGroupId = cardGroup?.id || undefined;
      }

      await API.card.batchIssueCard({
        ...data,
        cardGroupId,
        bankId,
        totalLimit: noTotalLimit ? null : totalLimit,
        dailyLimit: noDailyLimit ? null : dailyLimit,
        cardType: CardType.POSTPAID,
      });

      this.fetchWalletsAction();

      this.setLoading(false);
      this.completed = true;
      this.$emit("issued");
    } catch (error) {
      this.setLoading(false);
    }
  }

  private onClose() {
    this.$emit("close");
  }

  private initWatcherDepsForValidate() {
    const watchCallback = debounce(() => {
      this.validateFormCardLimit();
    }, 100);

    this.$watch(() => {
      return [
        this.dailyLimit,
        this.totalLimit,
        this.form.noDailyLimit,
        this.form.noTotalLimit,
      ].join("_");
    }, watchCallback);

    this.$once("hook:beforeDestroy", () => {
      watchCallback.cancel();
    });
  }

  private created() {
    this.fetchUserBanksAction();
    this.fetchAvailablePromocodesAction();
    this.fetchProfileAction();
  }

  private mounted() {
    this.initWatcherDepsForValidate();
  }
}
