import { render, staticRenderFns } from "./IssuePostpaidCardForm.vue?vue&type=template&id=225c74c6&"
import script from "./IssuePostpaidCardForm.ts?vue&type=script&lang=ts&"
export * from "./IssuePostpaidCardForm.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports