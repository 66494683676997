import { Component, Vue, Prop } from "vue-property-decorator";
import { copyTextClipboard, getSymbolCurrency } from "@helpers";
import { Bank } from "@/types/bank";
import { Currency } from "@/types/currency";
import { profileModule, walletModule, bankModule } from "@store/namespaces";
import { ProfileGetters, ProfileActions } from "@store/modules/profile/types";
import { WalletActions, WalletGetters } from "@store/modules/wallet/types";
import { BankActions, BankGetters } from "@store/modules/bank/types";

@Component({
  components: {
    CardIssuedInfo: () =>
      import("../components/CardIssuedInfo/CardIssuedInfo.vue"),
  },
})
export default class IssueCardMixin extends Vue {
  @walletModule.Getter("wallet")
  private readonly walletGetter!: WalletGetters["wallet"];
  @profileModule.Getter("profileEmail")
  protected readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Action("fetchProfile")
  protected readonly fetchProfileAction!: ProfileActions["fetchProfile"];
  @walletModule.Action("fetchWallets")
  protected readonly fetchWalletsAction!: WalletActions["fetchWallets"];
  @bankModule.Action("fetchUserBanks")
  protected readonly fetchUserBanksAction!: BankActions["fetchUserBanks"];
  @bankModule.Getter("loadingUserBanks")
  protected readonly loadingUserBanksGetter!: BankGetters["loadingUserBanks"];
  @profileModule.Getter("profileFullName")
  protected readonly profileFullNameGetter!: ProfileGetters["profileFullName"];
  @profileModule.Action("fetchAvailablePromocodes")
  protected readonly fetchAvailablePromocodesAction!: ProfileActions["fetchAvailablePromocodes"];
  @profileModule.Getter
  protected readonly profileFreeCardsTotalPromocode!: ProfileGetters["profileFreeCardsTotalPromocode"];

  @Prop({ type: Object })
  protected readonly selectedBank?: Bank;

  @Prop({ type: Boolean, default: false })
  protected readonly hiddenTitle!: boolean;

  protected get currentCurrency(): Currency {
    return this.selectedBank?.currency || Currency.EUR;
  }

  protected get currentBalance(): number {
    const currency = this.currentCurrency;

    return currency ? this.walletGetter(currency).balance : 0;
  }

  protected get currentSymbolCurrency(): string {
    return getSymbolCurrency(this.currentCurrency);
  }

  protected get currentCurrencyIcon(): string {
    return require(`@/assets/img/currency/flags/${this.currentCurrency}.svg`);
  }

  protected copyText(str: string): void {
    copyTextClipboard(str);

    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }

  protected onCancel(): void {
    this.$emit("cancel");
  }
}
